<template>
  <div class="table-box-content-div">
    <div class="table-title-div">
      <div class="left-div">
        <div class="data-title-div">{{ tableTitle }}</div>
        <div class="data-total-div">(共 {{ dataTotal }} 条记录)</div>
        <div>
          <img class="cursor-el" src="../assets/refresh.png" height="18" width="19" title="刷新" alt="刷新" @click="fetchData(1)" />
        </div>
      </div>
      <div>
        <slot name="title-right" />
      </div>
    </div>
    <div class="table-div">
      <table id="table" class="table">
        <thead>
          <tr class="table-head-tr">
            <slot name="table-head" />
          </tr>
          <tr class="table-sub-head-tr" v-if="$slots.tableSubHead">
            <slot name="tableSubHead" />
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-if="isLoading">
            <td :colspan="tableFieldNum">数据加载中...</td>
          </tr>
          <tr v-else-if="items.length === 0">
            <td :colspan="tableFieldNum">没有数据</td>
          </tr>
          <template v-else>
            <slot name="table-body" v-for="(item, index) in items" :item="item" :index="index" />
          </template>
        </tbody>
      </table>
    </div>
    <div class="table-footer-div">
      <label class="page-total-label">
        每页显示
        <select v-model="pageSize">
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        条
      </label>
      <img class="turn-page-image cursor-el" :src="getArrowLeft()" @click="onBeforePage" alt="" />
      <input v-if="dataPageTotal > 0" :class="{ 'page-num-btn': true, 'current-page-num': currentPageNum === 1 }" type="button" value="1" @click="onToPage(1)" />
      <label v-if="currentPageNum > 4 && dataPageTotal > 7">......</label>
      <input v-for="index in getPageNumBtn()" :key="index" :class="{ 'page-num-btn': true, 'current-page-num': currentPageNum === index + pageNumOffsets }" type="button" :value="index + pageNumOffsets" @click="onToPage(index + pageNumOffsets)" />
      <label v-if="dataPageTotal - currentPageNum > 3 && dataPageTotal > 7">......</label>
      <input v-if="dataPageTotal > 1" :class="{ 'page-num-btn': true, 'current-page-num': currentPageNum === dataPageTotal }" type="button" :value="dataPageTotal" @click="onToPage(dataPageTotal)" />
      <img class="turn-page-image cursor-el" :src="getArrowRight()" @click="onNextPage" alt="" />
      <label class="jump-txt-label">跳转到第</label>
      <label>
        <input class="page-num-input" type="text" v-model="turnPageNum" />
      </label>
      <label>页</label>
      <input class="jump-page-btn" type="button" value="确定" @click="onTurnPage" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: ""
    },
    tableFieldNum: {
      type: Number,
      default: 0
    },
    action: {
      type: Function,
      default: () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    reloadData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageSize: 20,
      dataTotal: 0,
      dataPageTotal: 0,
      currentPageNum: 0,
      pageNumOffsets: 0,
      turnPageNum: "",
      items: [],
      isLoading: false
    };
  },
  created() {
    this.fetchData(1);
  },
  mounted() {
    // this.initTable();
  },
  watch: {
    reloadData() {
      if (this.reloadData) {
        this.fetchData(1);
        this.$emit("update:reloadData", false);
      }
    },
    pageSize() {
      this.params.pageSize = this.pageSize;
    },
    params: {
      handler() {
        this.fetchData(1);
      },
      deep: true
    }
  },
  methods: {
    getArrowLeft() {
      return this.currentPageNum > 1 ? require("../assets/arrow-left-1.png") : require("../assets/arrow-left-2.png");
    },
    getArrowRight() {
      return this.currentPageNum < this.dataPageTotal ? require("../assets/arrow-right-1.png") : require("../assets/arrow-right-2.png");
    },
    fetchData(pageNum) {
      this.params.pageNum = pageNum;
      this.isLoading = true;
      this.items = [];
      this.action(this.params)
        .then(data => {
          if (data && data.list instanceof Array) {
            this.currentPageNum = pageNum;
            this.items = data.list;

            if(data.pageNum === 1){
              this.dataTotal = data.total;
              this.dataPageTotal = data.pages;
            }
          }
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询数据失败");
          }
        });
    },
    getPageNumBtn() {
      if (this.dataPageTotal < 8) {
        let total = this.dataPageTotal - 2;
        if (total > 0) {
          this.pageNumOffsets = 1;
          return total;
        } else {
          this.pageNumOffsets = 0;
          return 0;
        }
      }

      if (this.currentPageNum < 5) {
        this.pageNumOffsets = 1;
        return 4;
      } else {
        if (this.dataPageTotal - this.currentPageNum > 3) {
          this.pageNumOffsets = this.currentPageNum - 2;
          return 3;
        } else {
          this.pageNumOffsets = this.dataPageTotal - 5;
          return 4;
        }
      }
    },
    onBeforePage() {
      if (this.currentPageNum > 1) {
        this.fetchData(this.currentPageNum - 1);
      }
    },
    onNextPage() {
      if (this.currentPageNum < this.dataPageTotal) {
        this.fetchData(this.currentPageNum + 1);
      }
    },
    onTurnPage() {
      if (this.currentPageNum !== parseInt(this.turnPageNum) && this.turnPageNum >= 1 && this.turnPageNum <= this.dataPageTotal) {
        this.fetchData(parseInt(this.turnPageNum));
      }
    },
    onToPage(num) {
      if (this.currentPageNum !== num) {
        this.fetchData(num);
      }
    },
    initTable() {
      let table = document.getElementById("table");
      let offsetTableWidth = 0;
      for (let i = 0; i < table.rows[0].cells.length; ++i) {
        table.rows[0].cells[i].onmousedown = function(event) {
          if (event.target.nodeName !== "TH") {
            return;
          }

          let gapX = event.clientX;
          let nowThWidth = event.target.offsetWidth;

          let tableWidth = null;
          document.onmousemove = function(e) {
            let x = e.clientX - gapX;

            tableWidth = offsetTableWidth + x;
            if (tableWidth < 0) {
              tableWidth = 0;
            }
            table.style.width = `calc(100% + ${tableWidth}px)`;

            let thWidth = nowThWidth + x;
            if (thWidth < 70) {
              event.target.width = 70;
            } else {
              event.target.width = thWidth;
            }
            return false;
          };

          document.onmouseup = function() {
            document.onmousemove = null;
            document.onmouseup = null;
            if (tableWidth != null) {
              offsetTableWidth = tableWidth;
            }
          };
        };
      }
    }
  }
};
</script>

<style lang="less" scoped>
.table-box-content-div {
  width: 100%;
  .table-title-div {
    height: 35px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .left-div {
      line-height: 35px;
      display: flex;
      .data-title-div {
        font-size: 17px;
        font-weight: bold;
      }
      .data-total-div {
        margin-left: 10px;
        margin-right: 20px;
      }
      img {
        vertical-align: middle;
      }
    }
  }
  .table-div {
    width: 100%;
    overflow: auto;
    .table {
      width: 100%;
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      border: 1px solid #e5e5e5;
      text-align: center;
      .table-head-tr {
        height: 40px;
        background-color: #eeeeee;
        th:nth-child(n + 2) {
          border-left: 1px solid #e5e5e5;
        }
      }
      .table-sub-head-tr {
        height: 40px;
        font-size: 12px;
        text-align: left;
        border-bottom: 1px solid #e5e5e5;
      }
      .table-body {
        tr {
          height: 40px;
          font-size: 12px;
          td {
            padding: 0 5px;
          }
        }
        tr:hover {
          background-color: #ebf2fa;
        }
        tr:nth-child(n + 2) {
          border-top: 1px solid #e5e5e5;
        }
      }
    }
  }
  .table-footer-div {
    margin-top: 2px;
    padding: 40px 5px 30px 5px;
    text-align: right;
    font-size: 0;
    input,
    label,
    img {
      font-size: 12px;
      vertical-align: middle;
    }
    .page-total-label {
      margin-right: 15px;
      select {
        width: 60px;
        height: 25px;
        border: 1px solid #e5e5e5;
      }
    }
    .turn-page-image {
      width: 9px;
      height: 17px;
      margin: 0 5px;
    }
    .page-num-btn {
      width: 40px;
      height: 25px;
      border: 1px solid #e5e5e5;
      background: #eeeeee;
    }
    .current-page-num {
      background: #ffffff;
      color: #4676c8;
    }
    .jump-txt-label {
      margin-left: 10px;
    }
    .page-num-input {
      width: 38px;
      height: 23px;
      text-align: center;
      border: 1px solid #e5e5e5;
      margin: 0 5px;
    }
    .jump-page-btn {
      width: 55px;
      height: 25px;
      background: #eeeeee;
      border: 1px solid #dcdcdc;
      margin-left: 15px;
    }
  }
}
</style>
